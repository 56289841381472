import React, { useState, useEffect, useCallback } from "react";
import Navbar from "../../components/AdminNavbar/AdminNavbar";
import { useNavigate, NavLink, Outlet } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "./Indexpage.css";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import particlesConfig from "./particle-config";
import BackgroundDark from "../../assets/darkwall.png";

//Icons
import { BsArrowRight } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { RiNotification3Line } from "react-icons/ri";
import { MdRemoveCircleOutline } from "react-icons/md";
import { BsPersonBadge } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { BiTransfer, BiMoney } from "react-icons/bi";
import { VscCloseAll } from "react-icons/vsc";
import { CgAdd } from "react-icons/cg";

const Indexpage = (props) => {
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(true);
	const [isOpen2, setIsOpen2] = useState(false);

	const open2MenuHandler = () => {
		setIsOpen(true);
		setIsOpen2(!isOpen2);
	};

	const toggle = () => setIsOpen(!isOpen);
	const close = () => setIsOpen2(false);

	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.scrollY > 1) {
				setIsOpen2(false);
			} else {
				setIsOpen2(false);
			}
		});
	}, []);

	const menuItem = [
		{
			path: "/admindashboard/plans",
			name: "Plans",
			icon: <BiMoney />,
		},
		{
			path: "/admindashboard/topup",
			name: "TopUp",
			icon: <BiTransfer />,
		},
		{
			path: "/admindashboard/topupplan",
			name: "TopUpPlan",
			icon: <CgAdd />,
		},
		{
			path: "/admindashboard/depositapproval",
			name: "Deposit Approval",
			icon: <CgAdd />,
		},
		{
			path: "/admindashboard/kycverification",
			name: "KYC",
			icon: <BsPersonBadge />,
		},
		{
			path: "/admindashboard/withdrawrequest",
			name: "Withdrawals",
			icon: <MdRemoveCircleOutline />,
		},
		{
			path: "/admindashboard/sendmail",
			name: "SendEmail",
			icon: <AiOutlineMail />,
		},
		{
			path: "/admindashboard/sendnotification",
			name: "SendNotification",
			icon: <RiNotification3Line />,
		},
		{
			path: "/admindashboard/allusers",
			name: "Users",
			icon: <BsPersonBadge />,
		},
	];

	const logOut = async () => {
		try {
			const { data } = await axios.get(
				"https://mergingtradingllc.com/api/logout",
				{}
			);
			const showToastMessage = () => {
				toast.info(data.message, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};

			if (data.success === true) {
				showToastMessage();
				setTimeout(() => {
					Cookies.remove("jwt");
					navigate("/adminsignin");
				}, 1000);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	//tsParticles Functions
	const particlesInit = useCallback(async (engine) => {
		await loadFull(engine);
	}, []);

	const particlesLoaded = useCallback(async (container) => {
		// await console.log(container);
	}, []);

	return (
		<div className="adminDashboard-main">
			<Navbar
				themeSwitch={props.themeSwitch}
				theme={props.theme}
				open2MenuHandler={open2MenuHandler}
			/>
			<div className="dashboard-container">
				<Particles
					className="tsparticles-dashboard"
					init={particlesInit}
					loaded={particlesLoaded}
					options={particlesConfig}
				/>
				<div className="dashboard-background">
					<img
						className="dashboard-background-img"
						src={BackgroundDark}
						alt="background Dark"
					/>
				</div>
				<div className="dashboard-container-11">
					<div className={`dashboard-container-1 ${isOpen2 ? "active" : ""}`}>
						<div
							style={{ width: isOpen ? "200px" : "70px" }}
							className="dashboard-sidebar"
						>
							<div className="dashboard-top_section">
								<VscCloseAll
									onClick={open2MenuHandler}
									className="sidebar-close"
								/>
								{!isOpen ? (
									<BsArrowRight className="bars-icon" onClick={toggle} />
								) : (
									<BsArrowLeft className="bars-icon" onClick={toggle} />
								)}
							</div>
							{menuItem.map((item, index) => (
								<NavLink
									to={item.path}
									key={index}
									onClick={close}
									className="dashboard-link"
									style={{ justifyContent: isOpen ? "" : "center" }}
									activeclassname="dashboard-active"
								>
									<div className="dashboard-icon">{item.icon}</div>
									<div
										style={{ display: isOpen ? "block" : "none" }}
										className="dashboard-link_text"
									>
										{item.name}
									</div>
								</NavLink>
							))}
							<button
								style={{ width: isOpen ? "80px" : "60px" }}
								className="dashboard-btn"
								onClick={logOut}
							>
								<FiLogOut className="logout-icon" />
							</button>
						</div>
					</div>
					<div className="dashboard-container-2">
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Indexpage;
