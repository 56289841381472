import React, { useState, useEffect } from "react";
import "./TopUpPlan.css";
import CircleLoader from "react-spinners/CircleLoader";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import { toast } from "react-toastify";
import PlanTopup from "./PlanTopup";

const TopUpPlan = () => {
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [ongoingPlans, setOngoingPlans] = useState([]);
	const [userMail, setUserMail] = useState("");

	// eslint-disable-next-line no-extend-native
	Number.prototype.toLocaleFixed = function (n) {
		return this.toLocaleString(undefined, {
			minimumFractionDigits: n,
			maximumFractionDigits: n,
		});
	};

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	const duration = getRandomNumberBetween(10, 15);
	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Dropdown State One
	const [isToggled2, setIsToggled2] = useState(false);
	const openFeatureToggler2 = () => {
		setIsToggled2(!isToggled2);
		// setIsToggled(false);
	};

	//Get Plans Using Email Update
	const planInitials = { email: "" };
	const [plan, setPlan] = useState(planInitials);
	const handlePlanChange = (e) => {
		const { name, value } = e.target;
		setPlan((values) => ({
			...values,
			[name]: value,
		}));
	};

	const handlePlanSubmit = async (event) => {
		event.preventDefault();

		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);

		try {
			const allPlan = await axios.post(
				"https://mergingtradingllc.com/api/admin/userongoingplans",
				{
					email: plan.email,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(allPlan.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (allPlan.data.success === true) {
				setOngoingPlans(allPlan.data.ongoingPlans);
				setUserMail(allPlan.data.email);
				setPlan(planInitials);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="topupplan-container">
					<div className="topupplan-2">
						<div className="topupplan-3">
							<h2>Update Total Earned</h2>
						</div>
						<div className="topupplan-section">
							<div className="topupplan-section-1">
								<h5>Search User</h5>
								<div className="topupplan-icon" onClick={openFeatureToggler2}>
									{!isToggled2 ? (
										<IoIosArrowDown className="withdraw-icon" />
									) : (
										<IoIosArrowUp className="withdraw-icon" />
									)}
								</div>
							</div>
							<div className={`toggle-menu ${isToggled2 ? "active" : ""}`}>
								<form className="emailForm" onSubmit={handlePlanSubmit}>
									<label>User Email</label>
									<input
										name="email"
										value={plan.email}
										onChange={handlePlanChange}
										placeholder="Enter user Email"
										type="email"
										autoComplete="off"
										required
									/>

									<button type="submit">
										{loading2 ? (
											<ScaleLoader
												className="loader-icon"
												color={"rgb(230, 232, 235)"}
												loading={loading2}
												size={50}
												speedMultiplier={1}
											/>
										) : (
											"Submit"
										)}
									</button>
								</form>
							</div>
						</div>
					</div>
					<div className="topupplan-3">
						<h2>All User Plans</h2>
					</div>
					<div className="topupplan-4">
						{ongoingPlans.length !== 0
							? ongoingPlans.map((details) => {
								return <PlanTopup details={details} userMail={userMail} />;
							})
							: ""}
					</div>
				</div>
			)}
		</>
	);
};

export default TopUpPlan;
