import React, { useState, useEffect } from "react";
import "./Investment.css";
import { Link } from "react-router-dom";
import { BiTimer } from "react-icons/bi";
import { BiMoney } from "react-icons/bi";
import CircleLoader from "react-spinners/CircleLoader";
import { MdAccountBalanceWallet } from "react-icons/md";
import { IoMdCheckmarkCircle } from "react-icons/io";
import axios from "axios";
import { toast } from "react-toastify";
import { AiOutlineLineChart } from "react-icons/ai";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import ReactApexChart from "react-apexcharts";
import InvestmentChart from "./InvestmentChart";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Autoplay, Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// eslint-disable-next-line no-extend-native
Number.prototype.toLocaleFixed = function (n) {
	return this.toLocaleString(undefined, {
		minimumFractionDigits: n,
		maximumFractionDigits: n,
	});
};

const Investment = () => {
	const [plans, setPlans] = useState(false);
	const [loading, setLoading] = useState(false);
	const [investment, setInvestment] = useState();
	const [total7days, setTotal7days] = useState();
	const [total30days, setTotal30days] = useState();
	const [totalProfit, setTotalProfit] = useState();
	const [investmentDetails, setInvestmentDetails] = useState([]);
	const [ongoingPlans, setOngoingPlans] = useState([]);
	const [last7Dates, setLast7Dates] = useState([]);
	const [investmentLast7Prices, setinvestmentLast7Prices] = useState([]);
	const [profitLast7Prices, setprofitLast7Prices] = useState([]);
	const [profitChart7Prices, setprofitChart7Prices] = useState([]);

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(10, 15);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		try {
			const investmentDetails = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getallplans",
					{},
					{
						withCredentials: true,
					}
				);
				if (data.success === true) {
					setInvestmentDetails(data.allPlan);
				}
			};
			investmentDetails();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	}, [investmentDetails]);

	useEffect(() => {
		try {
			const getBalance = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getbalanceandplan",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setInvestment(data.wallet.investment.toLocaleFixed(2));
					setTotal7days(data.total7days.toLocaleFixed(2));
					setTotal30days(data.total30days.toLocaleFixed(2));

					if (data.ongoingPlans.length === 0) {
						const profitBalance = data.profitBalance;
						setTotalProfit(Math.round(profitBalance).toLocaleFixed(2));
						setPlans(true);
					} else {
						setOngoingPlans(data.ongoingPlans);
						const totalEarnedArray = data.ongoingPlans.map((plan) => {
							return plan.totalEarned;
						});
						const reducer = (accumulator, curr) => accumulator + curr;
						const profitBalance = data.profitBalance;
						const newTotalEarned =
							Math.round(totalEarnedArray.reduce(reducer) * 100) / 100;
						const newTotalProfit = profitBalance + newTotalEarned;
						setTotalProfit(newTotalProfit.toLocaleFixed(2));
					}
				}
			};
			getBalance();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [investment]);

	async function buyPlanHandler(id) {
		try {
			const deletePlan = await axios.post(
				"https://mergingtradingllc.com/api/buyplan",
				{
					id,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(deletePlan.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	}

	function firstLetter(str) {
		const arr = str.toLowerCase().split(" ");
		for (var i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		const str2 = arr.join(" ");
		return str2;
	}

	useEffect(() => {
		try {
			const getTransactions = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getinvestmentandprofitbalance",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setLast7Dates(data.last7Dates);
					setinvestmentLast7Prices(data.investmentLast7Prices);
					setprofitLast7Prices(data.profitLast7Prices);
					setprofitChart7Prices(data.profitChart7Prices);
				}
			};
			getTransactions();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const chartState1 = {
		series: [
			{
				name: "7 Days Balance",
				data: investmentLast7Prices,
			},
		],
		options: {
			chart: {
				height: 300,
				foreColor: "#fff",
				type: "area",
			},
			dataLabels: {
				enabled: false,
			},
			stroke: {
				curve: "smooth",
			},
			xaxis: {
				type: "datetime",
				categories: last7Dates,
			},
			tooltip: {
				x: {
					format: "dd/MM/yy",
				},
			},
		},
	};

	const chartState2 = {
		series: [
			{
				name: "7 Days Balance",
				data: profitChart7Prices,
			},
		],
		options: {
			chart: {
				height: 300,
				foreColor: "#fff",
				type: "candlestick",
				animations: {
					enabled: true,
					easing: "easeinout",
					speed: 800,
					animateGradually: {
						enabled: true,
						delay: 50,
					},
					dynamicAnimation: {
						enabled: true,
						speed: 500,
					},
				},
			},
			xaxis: {
				type: "datetime",
				categories: last7Dates,
			},
			tooltip: {
				enabled: true,
			},
			yaxis: {
				tooltip: {
					enabled: true,
				},
			},
			plotOptions: {
				candlestick: {
					wick: {
						useFillColor: true,
					},
				},
			},
		},
	};

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="investment-container">
					<div className="investment-2">
						<div className="investment-2-1">
							<div className="investment-2-1-1">
								<div className="investmentbalance2">
									<div className="investmentbalance2-1">
										<div className="investmentbalance2-1-1">
											<div className="investmentbalance2-1-iconcontainer">
												<MdAccountBalanceWallet className="investmentbalance2-1-icon" />
											</div>
											<p>Investment Balance</p>
										</div>
										<div className="investmentbalance2-1-2">
											<h2>${investment}</h2>
										</div>
									</div>
									<div className="investmentbalance2-1">
										<div className="investmentbalance2-1-1">
											<div className="investmentbalance2-1-iconcontainer">
												<MdAccountBalanceWallet className="investmentbalance2-1-icon" />
											</div>
											<p>Total Profit Balance</p>
										</div>
										<div className="investmentbalance2-1-2">
											<h2>${totalProfit}</h2>
										</div>
									</div>
								</div>
								<div className="charts-container">
									<div className="charts-container-2">
										<ReactApexChart
											options={chartState2.options}
											series={chartState2.series}
											type="candlestick"
											height={360}
											width={400}
										/>
										<p>LIVE PROFIT CHART BALANCE</p>
									</div>
									<div className="charts-container-2">
										<ReactApexChart
											options={chartState1.options}
											series={chartState1.series}
											type="area"
											height={360}
											width={400}
										/>
										<p> INVESTMENT BALANCE CHART</p>
									</div>
								</div>
								<div className="verified-investment">
									Link to Deposit Page:
									<Link className="investment-link" to="/dashboard/deposit">
										Deposit Page
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="investment-3">
						<div className="investment-5">
							<div className="investment-5-1">
								<div className="investment-5-1-1">
									<div className="investment-5-1-iconcontainer">
										<AiOutlineLineChart className="investment-5-1-icon" />
									</div>
									<p>Total Earned 7 days</p>
								</div>
								<div className="investment-5-2">
									<h2>${total7days}</h2>
								</div>
							</div>
							<div className="investment-5-1">
								<div className="investment-5-1-1">
									<div className="investment-5-1-iconcontainer">
										<AiOutlineLineChart className="investment-5-1-icon" />
									</div>
									<p>Total Earned 30 days</p>
								</div>
								<div className="investment-5-2">
									<h2>${total30days}</h2>
								</div>
							</div>
						</div>
						{plans ? (
							<div className="investment-3-1">
								<h1>No Plan Bought</h1>
							</div>
						) : (
							<div className="investment-3-2">
								<h3>ONGOING PLANS</h3>
								<Swiper
									modules={[Keyboard, Autoplay, Pagination]}
									slidesPerView={1}
									spaceBetween={100}
									effect={"fade"}
									mousewheel={true}
									direction={"horizontal"}
									keyboard={{
										enabled: true,
									}}
									centeredSlides={true}
									autoplay={{
										delay: 300000,
										disableOnInteraction: true,
									}}
									className="mySwiperInvestment"
								>
									{ongoingPlans.length !== 0
										? ongoingPlans.map((plan) => {
											return (
												<SwiperSlide
													className="swiperSlideInvestment"
													key={plan._id}
												>
													<div className="investment-3-2-1-container">
														<div className="investment-3-2-1">
															<h4>{plan.planName}</h4>
															<ul>
																<li>
																	<BiTimer className="investment-3-icon" />
																	Days Left: <span>{plan.daysLeft} Days</span>
																</li>
																<li>
																	<BiMoney className="investment-3-icon" />
																	Total Earned:{" "}
																	<span>
																		${plan.totalEarned.toLocaleFixed(2)}
																	</span>{" "}
																</li>
															</ul>
														</div>
														<InvestmentChart plan={plan} />
													</div>
												</SwiperSlide>
											);
										})
										: ""}
								</Swiper>
							</div>
						)}

						<h2>Plans Available</h2>
					</div>
					<div className="investment-4">
						{investmentDetails.length !== 0
							? investmentDetails.map((details) => {
								return (
									<div className="plans-4-text" key={details.id}>
										<h3>{firstLetter(details.planName)}</h3>
										<Popup
											trigger={<button>Plan Details</button>}
											modal
											position="bottom center"
										>
											{(close) => (
												<div className="investment-modal">
													<button className="close" onClick={close}>
														&times;
													</button>
													<ul>
														<li>
															<IoMdCheckmarkCircle className="plans-4-icon" />{" "}
															Lock Time: <span>{details.lockTime}</span>
														</li>
														<li>
															<IoMdCheckmarkCircle className="plans-4-icon" />{" "}
															Plan Return: <span>{details.planReturn}</span>{" "}
														</li>
														<li>
															<IoMdCheckmarkCircle className="plans-4-icon" />{" "}
															Price: <span>{details.minimumBuy}</span>
														</li>
													</ul>

													<ul className="ul-modal">
														<li>
															1. Investment Objective: The objective of this
															investment plan is to generate long-term capital
															growth
														</li>
														<li>
															2. Investment Horizon: The minimum investment
															horizon for this plan is shown above. Early
															withdrawals or transfers may result in a
															penalty, which will be disclosed in the
															prospectus. Contact us in such case.
														</li>
														<li>
															3. Fees and Expenses: No fees or expenses other
															than the amount listed above is charged for this
															investment.
														</li>
														<li>
															4. Asset Allocation: The asset allocation of the
															investment plan will be periodically reviewed
															and may be adjusted based on market conditions
															or changes in the investment objective.
														</li>
														<li>
															5. Redemption: Investors may redeem their shares
															at any time once matured.{" "}
														</li>
														<li>
															6. Amendment: The terms and conditions of the
															investment plan may be amended from time to time
															by the fund manager. Any material changes will
															be communicated to investors.
														</li>
														<li>
															7. Agreement: clicking on the "Buy Plan" Button
															Below means you are binded by the above listed
															conditions with respect to your investment.{" "}
														</li>
													</ul>
													<button
														className="plans-4-button"
														onClick={() => {
															buyPlanHandler(details._id);
															close();
														}}
													>
														Buy Plan
													</button>
												</div>
											)}
										</Popup>
									</div>
								);
							})
							: ""}
					</div>
				</div>
			)}
		</>
	);
};

export default Investment;
