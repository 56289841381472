import React, { useState, useEffect } from "react";
import "./Plans.css";
import CircleLoader from "react-spinners/CircleLoader";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import ScaleLoader from "react-spinners/ScaleLoader";
import axios from "axios";
import { toast } from "react-toastify";

const Plans = () => {
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [plansDetails, setPlanDetails] = useState([]);

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}

	const duration = getRandomNumberBetween(10, 15);
	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	//Dropdown State One
	const [isToggled2, setIsToggled2] = useState(false);
	const openFeatureToggler2 = () => {
		setIsToggled2(!isToggled2);
		// setIsToggled(false);
	};

	//Send to One
	const planInitials = { name: "", lockTime: "", return: "", minimumBuy: "" };
	const [plan, setPlan] = useState(planInitials);
	const handlePlanChange = (e) => {
		const { name, value } = e.target;
		setPlan((values) => ({
			...values,
			[name]: value,
		}));
	};

	const handlePlanSubmit = async (event) => {
		event.preventDefault();
		// console.log(plan);
		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);

		try {
			const addPlan = await axios.post(
				"https://mergingtradingllc.com/api/admin/addplan",
				{
					planName: plan.name,
					lockTime: plan.lockTime,
					planReturn: plan.return,
					minimumBuy: plan.minimumBuy,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(addPlan.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (addPlan.data.success === true) {
				setPlan(planInitials);
			}
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	async function deleteHandler(id) {
		try {
			const deletePlan = await axios.post(
				"https://mergingtradingllc.com/api/admin/deleteplan",
				{
					id,
				},
				{
					withCredentials: true,
				}
			);

			const showToastMessage = () => {
				toast.info(deletePlan.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	}

	useEffect(() => {
		try {
			const getPlans = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/admin/allplans",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setPlanDetails(data.allPlan);
				}
			};
			getPlans();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	}, [plansDetails]);

	function firstLetter(str) {
		const arr = str.toLowerCase().split(" ");
		for (var i = 0; i < arr.length; i++) {
			arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
		}
		const str2 = arr.join(" ");
		return str2;
	}

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="plans-container">
					<div className="plans-2">
						<div className="plans-3">
							<h2>Add Investment Plan</h2>
						</div>
						<div className="plans-section">
							<div className="plans-section-1">
								<h5>Add Plan</h5>
								<div className="plans-icon" onClick={openFeatureToggler2}>
									{!isToggled2 ? (
										<IoIosArrowDown className="withdraw-icon" />
									) : (
										<IoIosArrowUp className="withdraw-icon" />
									)}
								</div>
							</div>
							<div className={`toggle-menu ${isToggled2 ? "active" : ""}`}>
								<form className="emailForm" onSubmit={handlePlanSubmit}>
									<label>Plan Name</label>
									<input
										name="name"
										value={plan.name}
										onChange={handlePlanChange}
										placeholder="Enter plan name"
										type="text"
										autoComplete="off"
										required
									/>
									<label>Lock Time</label>
									<input
										name="lockTime"
										value={plan.lockTime}
										onChange={handlePlanChange}
										placeholder="Enter lock time"
										type="text"
										autoComplete="off"
										required
									/>
									<label>Plan Return</label>
									<input
										name="return"
										value={plan.return}
										onChange={handlePlanChange}
										placeholder="Enter return %"
										type="text"
										autoComplete="off"
										required
									/>
									<label>Price</label>
									<input
										name="minimumBuy"
										value={plan.minimumBuy}
										onChange={handlePlanChange}
										placeholder="Enter price"
										type="text"
										autoComplete="off"
										required
									/>
									<button type="submit">
										{loading2 ? (
											<ScaleLoader
												className="loader-icon"
												color={"rgb(230, 232, 235)"}
												loading={loading2}
												size={50}
												speedMultiplier={1}
											/>
										) : (
											"Submit"
										)}
									</button>
								</form>
							</div>
						</div>
					</div>
					<div className="plans-3">
						<h2>Plans Added</h2>
					</div>
					<div className="plans-4">
						{plansDetails.length !== 0
							? plansDetails.map((details) => {
								return (
									<div className="plans-4-text" key={details.id}>
										<h3>{firstLetter(details.planName)}</h3>
										<ul>
											<li>
												<IoMdCheckmarkCircle className="plans-4-icon" /> Lock
												Time: <span>{details.lockTime}</span>
											</li>
											<li>
												<IoMdCheckmarkCircle className="plans-4-icon" /> Plan
												Return: <span>{details.planReturn}</span>{" "}
											</li>
											<li>
												<IoMdCheckmarkCircle className="plans-4-icon" />{" "}
												Price: <span>{details.minimumBuy}</span>
											</li>
										</ul>
										<button
											className="plans-4-button"
											onClick={() => deleteHandler(details._id)}
										>
											Delete
										</button>
									</div>
								);
							})
							: ""}
					</div>
				</div>
			)}
		</>
	);
};

export default Plans;
