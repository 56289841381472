import React, { useState, useEffect } from "react";
import "./Notification.css";
import Notify from "react-faq-component";
import notificationImg from "../../../assets/notification1.png";
import CircleLoader from "react-spinners/CircleLoader";
import axios from "axios";
import { toast } from "react-toastify";

const Notification = () => {
	const [loading, setLoading] = useState(false);
	const [notifications, setNotifications] = useState([]);

	const markRead = () => {
		try {
			const getStatus = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/setnotifications",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
				}
			};
			getStatus();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(10, 15);

	useEffect(() => {
		markRead();
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const showNotificationHandler = () => {
		if (totalNotification > 0) {
			showNotification(false);
		} else {
			showNotification(true);
		}
	};

	useEffect(() => {
		try {
			const getStatus = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getnotifications",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setNotifications(data.notifications);
				}
			};
			getStatus();
			showNotificationHandler();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notifications]);

	const data = {
		title: "Your Notification's",
		rows:
			notifications.length !== 0
				? notifications.map((notification) => {
					return {
						title: `${notification.title} -- ${notification.date
							.slice(0, 16)
							.replace("T", "-")}`,
						content: `${notification.subject}`,
					};
				})
				: [],
	};

	const styles = {
		bgColor: "#4F4174",
		titleTextColor: "#fff",
		titleTextSize: "3rem",
		rowTitleColor: "#FF7470",
		rowTitleTextSize: "large",
		rowContentColor: "#74B4FF",
		arrowColor: "#74B4FF",
	};

	const config = {
		animate: true,
		tabFocus: true,
	};

	const totalNotification = data.rows.length;
	const [notification, showNotification] = useState(true);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="notification-container">
					<div className="notification-2">
						<div className="notification-2-img">
							<img src={notificationImg} alt="notification Img" />
						</div>
						{notification ? (
							<h3>You have no Notification...</h3>
						) : (
							<div className="notification-details">
								<Notify data={data} styles={styles} config={config} />
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default Notification;
