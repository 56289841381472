import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Indexpage/Indexpage';
import Signup from './pages/Signup/indexpage';
import Signin from './pages/Signin/indexpage';
import SigninAdmin from './pages/SigninAdmin/indexpage';
import Passwordrecovery from "./pages/Passwordrecovery/indexpage";
import Resetpassword from "./pages/Resetpassword/indexpage";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollToTop from "react-scroll-to-top";
import useLocalStorage from 'use-local-storage';

//User Dashboard Links
import Userdashboard from "./pages/UserDashboard/Indexpage";
import Dashboard from "./pages/UserDashboard/Dashboard/Dashboard";
import Investment from "./pages/UserDashboard/Investment/Investment";
import Deposit from "./pages/UserDashboard/Deposit/Deposit";
import Transfer from "./pages/UserDashboard/Transfer/Transfer"
import Buy from './pages/UserDashboard/Buy/Buy';
import Withdrawal from "./pages/UserDashboard/Withdrawal/Withdrawal.js";
import Kyc from "./pages/UserDashboard/Kyc/Kyc";
import Notification from "./pages/UserDashboard/Notification/Notification";
import Cardrequest from "./pages/UserDashboard/Cardrequest/Cardrequest";
import Profile from './pages/UserDashboard/Profile/Profile';
import PrivateRoutes from './components/PrivateRoutes';

//Admin Dashboard Links
import AdminMainDashboard from "./pages/AdminDashboard/Indexpage";
import AdminKycVerification from "./pages/AdminDashboard/KycVerification/KycVerification";
import AdminWithdrawrequest from "./pages/AdminDashboard/Withdrawrequest/Withdrawrequest";
import AdminTopUp from "./pages/AdminDashboard/TopUp/TopUp";
import AdminTopUpPlan from "./pages/AdminDashboard/TopUpPlan/TopUpPlan";
import AdminUserDeposit from "./pages/AdminDashboard/UserDeposits/UserDeposits";
import AdminSendMail from "./pages/AdminDashboard/SendMail/SendMail";
import AdminSendNotification from "./pages/AdminDashboard/SendNotification/SendNotification";
import AdminPlans from "./pages/AdminDashboard/Plans/Plans";
import AdminAllUsers from "./pages/AdminDashboard/AllUsers/AllUsers";
import PrivateRoutesAdmin from './components/PrivateRoutesAdmin';

function App() {
  
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'light');

  const themeSwitch = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  }

  return (
    <div data-theme={theme}>
      <BrowserRouter>
        <ScrollToTop height='20' smooth="true" color="var(--primary-500)" style={{marginRight:"3rem", marginBottom: "-1rem"}} top="500"/>
        <ToastContainer />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/adminsignin" element={<SigninAdmin />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/recovery" element={<Passwordrecovery />} />
          <Route path="/resetpassword/:id/:token" element={<Resetpassword />} />
          
          {/* User Dashboard Routes */}
          <Route element={<PrivateRoutes data-theme={theme}/>}>
            <Route path="/dashboard" element={<Userdashboard themeSwitch={themeSwitch} theme={theme}/>}>
              <Route path="home" element={<Dashboard />} />
              <Route path="investment" element={<Investment />} />
              <Route path="profile" element={<Profile />} />
              <Route path="deposit" element={<Deposit />} />
              <Route path="transfer" element={<Transfer />} />
              <Route path="buy" element={<Buy />} />
              <Route path="withdrawal" element={<Withdrawal />} />
              <Route path="virtualcard" element={<Cardrequest />} />
              <Route path="kyc" element={<Kyc />} />
              <Route path="notification" element={<Notification />} />
            </Route> 
          </Route>

          {/* Admin Dashboard Routes */}
          <Route element={<PrivateRoutesAdmin />}>
            <Route path="/admindashboard" element={<AdminMainDashboard themeSwitch={themeSwitch} theme={theme}/>}>
              <Route path="withdrawrequest" element={<AdminWithdrawrequest />} Admin/>
              <Route path="kycverification" element={<AdminKycVerification />} />
              <Route path="topup" element={<AdminTopUp />} />
              <Route path="depositapproval" element={<AdminUserDeposit />} />
              <Route path="topupplan" element={<AdminTopUpPlan />} />
              <Route path="sendmail" element={<AdminSendMail />} />
              <Route path="sendnotification" element={<AdminSendNotification />} />
              <Route path="plans" element={<AdminPlans />} />
              <Route path="allusers" element={<AdminAllUsers />} />
            </Route>  
          </Route>
          {/* Wrong/Unreachable Routes */}
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
