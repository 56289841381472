/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./Deposit.css";
import btcLogo from "../../../assets/btc.png";
import { MdCopyAll } from "react-icons/md";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import CircleLoader from "react-spinners/CircleLoader";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { CgAdd } from "react-icons/cg";
import axios from "axios";
import QRCode from "qrcode";
import { useFormik } from "formik";
import * as yup from "yup";
import ScaleLoader from "react-spinners/ScaleLoader";

const Deposit = () => {
	//Dropdown State Wallet
	const [isToggled, setIsToggled] = useState(true);
	const openFeatureToggler = () => {
		setIsToggled(!isToggled);
	};
	const [loading, setLoading] = useState(false);
	const [loading2, setLoading2] = useState(false);
	const [address, setAddress] = useState("");
	const [qr, setQr] = useState("");

	const GenerateQRCode = () => {
		QRCode.toDataURL(
			address,
			{
				width: 800,
				margin: 2,
				color: {
					dark: "#2684fe",
					light: "#ffffff00",
				},
			},
			(err, url) => {
				// if (err) return console.error(err)
				// console.log(url)
				setQr(url);
			}
		);
	};

	useEffect(() => {
		try {
			const getAddress = async () => {
				const { data } = await axios.post(
					"https://mergingtradingllc.com/api/getaddress",
					{},
					{
						withCredentials: true,
					}
				);

				if (data.success === true) {
					setAddress(data.address);
				}
				GenerateQRCode();
			};
			getAddress();
		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	}, [address, GenerateQRCode]);

	function getRandomNumberBetween(min, max) {
		return Math.floor(Math.random() * (max - min + 1) + min);
	}
	const duration = getRandomNumberBetween(10, 15);

	useEffect(() => {
		setLoading(true);
		setTimeout(() => {
			setLoading(false);
		}, `${duration}00`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	//toastify for Address copy
	const showToastMessage = () => {
		toast.success("Copied.", {
			position: toast.POSITION.TOP_RIGHT,
			className: "toast-message",
		});
	};

	const onSubmit = async (values, actions) => {
		// console.log(values);
		// console.log(actions);
		setLoading2(true);
		setTimeout(() => {
			setLoading2(false);
		}, 950);

		try {
			const depositSubmit = await axios.post(
				"https://mergingtradingllc.com/api/depositsubmit",
				{
					amount: values.amount,
					address: values.address,
				},
				{
					withCredentials: true,
				}
			);

			setLoading2(false);
			const showToastMessage = () => {
				toast.info(depositSubmit.data.status, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();

			if (depositSubmit.data.success === true) {
				actions.resetForm();
			}

		} catch (err) {
			const showToastMessage = () => {
				toast.error(err.response.data.error, {
					position: toast.POSITION.TOP_RIGHT,
					className: "toast-message",
				});
			};
			showToastMessage();
		}
	};

	const basicSchema = yup.object().shape({
		amount: yup.string().required("Required"),
		address: yup.string().min(2, "Name must be at least 2 characters long"),
	});

	const {
		values,
		errors,
		touched,
		isSubmitting,
		handleBlur,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			amount: "",
			address: ""
		},
		validationSchema: basicSchema,
		onSubmit,
	});

	return (
		<>
			{loading ? (
				<div className="loader">
					<CircleLoader
						color={"rgb(38, 132, 254)"}
						loading={loading}
						size={300}
						speedMultiplier={2}
					/>
				</div>
			) : (
				<div className="deposit-container">
					<div className="deposit-2">
						<div className="deposit-2-1-1">
							<h2 className="depositbalance"> Deposit Options </h2>
						</div>
						<div className="deposit-section">
							<div className="deposit-section-1">
								<CgAdd className="withdraw-icon" />
								<h5>Deposit using BTC</h5>
								<div className="deposit-icon" onClick={openFeatureToggler}>
									{!isToggled ? (
										<IoIosArrowDown className="withdraw-icon" />
									) : (
										<IoIosArrowUp className="withdraw-icon" />
									)}
								</div>
							</div>
							<div className={`toggle-menu ${isToggled ? "active" : ""}`}>
								<div className="deposit-2-1">
									<h3>Deposit is only through BTC payment for now.</h3>
									<p>Minimum Deposit: $50</p>
									<p>Average Arrival time: 10 min</p>
								</div>
								<div className="deposit-2-2">
									<h4>BTC Address</h4>
									<div className="deposit-img">
										<img src={qr} alt="qrcode" />
									</div>
									<p className="flex items-center justify-center gap-1 mb-3">
										<span className="address-text">{address}</span>
										<span>
											<CopyToClipboard text={address} onCopy={showToastMessage}>
												<MdCopyAll className="copy-icon" />
											</CopyToClipboard>
										</span>
									</p>
								</div>
								<div className="deposit-2-3">
									<div className="deposit-2-3-text">
										<h5>Send only BTC to this address.</h5>
										<p>
											Sending coin or token other than BTC to <br /> this
											address would result in loss of deposit.
										</p>
									</div>
									<div className="btclogo-img">
										<img src={btcLogo} alt="btcLogo" />
									</div>
								</div>

							<form onSubmit={handleSubmit} autoComplete="off">
							<h2 className="font-bold">After Deposit, submit form with deposit details</h2>
								<div className="line-divider"></div>
								<label htmlFor="amount">BTC Amount Sent</label>
								<input
									id="amount"
									type="string"
									placeholder="Enter amount(BTC) sent"
									value={values.amount}
									onChange={handleChange}
									onBlur={handleBlur}
									className={errors.amount && touched.amount ? "input-error" : ""}
								/>
								{errors.amount && touched.amount && (
									<p className="error">{errors.amount}</p>
								)}

								<label htmlFor="address">BTC Address sent From</label>
								<input
									id="address"
									type="string"
									placeholder="Enter your address"
									value={values.address}
									onChange={handleChange}
									onBlur={handleBlur}
									className={errors.address && touched.address ? "input-error" : ""}
								/>
								{errors.address && touched.address && (
									<p className="error">{errors.address}</p>
								)}

								<button disabled={isSubmitting} type="submit">
									{loading2 ? (
										<ScaleLoader
											className="loader-icon"
											color={"rgb(255, 255, 255)"}
											loading={loading2}
											size={50}
											speedMultiplier={1}
										/>
									) : (
										"Submit"
									)}
								</button>
							</form>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Deposit;
