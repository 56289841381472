import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import CircleLoader from "react-spinners/CircleLoader";
import ScaleLoader from "react-spinners/ScaleLoader";

const DepositsPage = () => {
    const [deposits, setDeposits] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingStates, setLoadingStates] = useState({}); // Track loading state for each deposit

    // Function to fetch all deposits
    const fetchDeposits = async () => {
        try {
            const response = await axios.post('/api/admin/alldeposits', { withCredentials: true });
            if (response.data.success) {
                setDeposits(response.data.allDepositRequests);
            }
        } catch (error) {
            toast.error('Error fetching deposits: ' + error.message, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'toast-message',
            });
        } finally {
            setLoading(false);
        }
    };

    // Handle deposit acceptance
    const handleAcceptDeposit = async (email, id) => {
        setLoadingStates((prev) => ({ ...prev, [id]: true })); // Set loading for the specific deposit
        try {
            const response = await axios.post(
                'https://mergingtradingllc.com/api/admin/acceptdeposit',
                { email, id },
                { withCredentials: true }
            );

            if (response.data.success) {
                toast.success('Deposit accepted successfully!', {
                    position: toast.POSITION.TOP_RIGHT,
                    className: 'toast-message',
                });
                // Refresh deposits list after accepting
                fetchDeposits();
            }
        } catch (error) {
            toast.error('Error accepting deposit: ' + error.message, {
                position: toast.POSITION.TOP_RIGHT,
                className: 'toast-message',
            });
        } finally {
            setLoadingStates((prev) => ({ ...prev, [id]: false })); // Reset loading for the specific deposit
        }
    };

    // Fetch deposits on component mount
    useEffect(() => {
        fetchDeposits();
    }, []);

    return (
        <div className="container mx-auto">
            <h1 className="text-2xl font-bold my-4 text-white">Deposit Requests</h1>
            {loading ? (
                <div className="loader">
                    <CircleLoader
                        color={"rgb(38, 132, 254)"}
                        loading={loading}
                        size={300}
                        speedMultiplier={2}
                    />
                </div>
            ) : (
                <div className="flex flex-wrap gap-[2rem]">
                    {deposits.map((deposit) => (
                        <div key={deposit.id} className="bg-gray-800 p-4 shadow-md w-fit rounded-lg">
                            <div className="text-white mb-2 flex flex-col gap-[1rem]">
                                <span className="text-green-500 font-bold">Email: </span>
                                <span>{deposit.email}</span>
                            </div>

                            <div className="text-white mb-2 flex flex-col gap-[1rem]">
                                <span className="text-green-500 font-bold">Amount: </span>
                                <span>{deposit.amount}</span>
                            </div>

                            <div className="text-white mb-2 flex flex-col gap-[1rem]">
                                <span className="text-green-500 font-bold">Address: </span>
                                <span>{deposit.address}</span>
                            </div>

                            <form className="emailForm" onSubmit={(e) => e.preventDefault()}>
                                <button
                                    type="submit"
                                    className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                                    onClick={() => handleAcceptDeposit(deposit.email, deposit.id)}
                                    disabled={loadingStates[deposit.id]} // Disable button when loading
                                >
                                    {loadingStates[deposit.id] ? (
                                        <ScaleLoader
                                            className="loader-icon"
                                            color={"rgb(230, 232, 235)"}
                                            loading={loadingStates[deposit.id]}
                                            size={50}
                                            speedMultiplier={1}
                                        />
                                    ) : (
                                        "Accept Deposit"
                                    )}
                                </button>
                            </form>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DepositsPage;
